<template>
  <ui-dialog
    class="ui-data-dialog"
    ref="innerDialog"
  >
    <template #trigger>
      <slot
        name="trigger"
        v-bind="{innerModel, setModel}"
      ></slot>
    </template>
    <template #contents>
      <slot
        name="contents"
        v-bind="{innerModel, setModel}"
      ></slot>
    </template>
    <template #footer="{close}">
      <button
        :disabled="isDisabled"
        type="button"
        class="ui-button --main"
        @click="accept(); close()"
      >Aceptar</button>
      <button
        type="button"
        class="ui-button --cancel"
        @click="cancel(); close()"
      >Cancelar</button>

      <slot
        name="footer"
        v-bind="{innerModel, setModel}"
      ></slot>
    </template>

  </ui-dialog>
</template>

<script>
import { UiDialog } from '@/modules/ui/components';

export default {
  name: 'ui-data-dialog',
  components: { UiDialog },

  props: {
    value: {
      required: false,
      default: null,
    },

    disabled: {
      required: false,
      default: false,
    },
  },

  data() {
    return {
      innerModel: null,
    };
  },

  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        this.innerModel = this.sanitizeValue(newValue);
      },
    },
  },

  computed: {
    isDisabled() {
      return typeof this.disabled == 'function'
        ? this.disabled(this.innerModel)
        : this.disabled;
    },
  },

  methods: {
    setModel(newValue) {
      this.innerModel = newValue;
    },

    sanitizeValue(newValue) {
      return newValue ? JSON.parse(JSON.stringify(newValue)) : null;
    },

    accept() {
      this.$emit('input', JSON.parse(JSON.stringify(this.innerModel)));
    },

    cancel() {
      this.innerModel = this.sanitizeValue(this.value);
    },

    doClose() {
      return this.$refs.innerDialog.doClose();
    },

    doOpen() {
      return this.$refs.innerDialog.doOpen();
    },
  },
};
</script>